import type { NextPage } from 'next';
import Error from 'next/error';

import * as Sentry from '@sentry/nextjs';

import { renderComponents, getSlug } from '@lib/utils';
import { globalContentController, pageController, productController } from '@controllers/index';
import { rowFactory } from '@factories/index';

import { PageType } from '@lib/types';

import { logger } from '@lib/logger';
import { useEffect } from 'react';
import { StickyCheckoutFooter } from '@components/Molecules/StickyCheckoutFooter/StickyCheckoutFooter';
import useBasketContext from '@hooks/useBasketContext';
import { useRouter } from 'next/router';
import usePostcodeContext from '@hooks/usePostcodeContext';

export const getServerSideProps = async ({ resolvedUrl }: any) => {
  const slug = getSlug({ type: PageType.PAGE, resolvedUrl });

  if (slug === 'favicon.ico') return { props: {} };

  let page: any = {};
  let pages: any = [];
  let products: any = [];
  let footerData: any = [];
  let promoData: any = [];
  let salesCountdownData: any = [];
  let registerInterestData: any = [];

  try {
    page = await pageController.getPageBySlug({ slug });
    pages = await pageController.getPages();
    products = await productController.getProducts(
      slug === 'everydayfibre' ? 'Everyday Fibre' : ' ',
    );
    footerData = await globalContentController.getFooter();
    promoData = await globalContentController.getPromoData();
    salesCountdownData = await globalContentController.getSalesCountdownData();
    registerInterestData = await globalContentController.getRegisterInterestData();
  } catch (err) {
    Sentry.captureException(err);
    logger.error(err);
  }

  const rows = pageController.getRows({ page });
  const headerData = pageController.getHeaderData({ page, pages });
  const metadata = pageController.getMetadata({ page });
  const featureList = pageController.getFeatureList({ page });

  if (promoData && page?.promoBanner) {
    promoData['displayOnPage'] = page?.promoBanner;
  }

  if (salesCountdownData && page?.salesCountdown) {
    salesCountdownData['displayOnPage'] = page?.salesCountdown;
  }

  const themeFromRows = rows.find(({ type }) => type === 'hero')?.value?.theme;

  let headerInverted = false;
  let gradientBackground = false;

  if (themeFromRows) {
    if (themeFromRows === 'light') {
      headerInverted = true;
      gradientBackground = true;
    }
  } else {
    if (headerData && headerData.theme === 'light') {
      headerInverted = true;
      gradientBackground = true;
    }
  }

  return {
    props: {
      metadata,
      promoData,
      salesCountdownData,
      headerData,
      footerData,
      featureList: { list: featureList },
      registerInterestData,
      data: {
        page,
        products,
        rows,
      },
      layoutProps: { headerInverted, gradientBackground },
    },
  };
};

const Page: NextPage = ({ headerData, data }: any) => {
  const hash = typeof window !== 'undefined' ? window.location.hash : null;
  const { basketItem } = useBasketContext();
  const router = useRouter();
  const showStickyCheckoutFooter =
    router.asPath === '/' ||
    router.asPath === '/business' ||
    router.asPath === '/home-worker' ||
    router.asPath === '/for-home' ||
    router.asPath.includes('/coverage');
  const { postcodeItem } = usePostcodeContext();

  useEffect(() => {
    if (window.location.hash) {
      const element = document.querySelector(window.location.hash);
      element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, [hash]);

  if (!headerData || !data.page || !data.page.display) {
    return <Error statusCode={404} />;
  }

  const components = data?.rows?.map(({ type, value }: any) => {
    const Component = rowFactory.createRow({
      type,
      attributes: value,
      products: data?.products,
    });

    return Component;
  });

  return (
    <>
      {renderComponents({ components })}
      {basketItem && showStickyCheckoutFooter && (
        <StickyCheckoutFooter
          primaryText={'Proceed'}
          primaryHref={!postcodeItem ? '/address-finder' : '/service-property-confirmation'}
          show={true}
          isAlwaysOnScreen={true}
          isSticky={true}
        />
      )}
    </>
  );
};

export default Page;
